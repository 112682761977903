@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.esmaModal {
  :global(.modalDialog) {
    width: 450px;
  }

  :global(.modalHeader) {
    padding-top: 20px;
  }

  @include mqMaxWidth($screenS) {
    :global(.modalBody) {
      padding: 10px;
    }
  }

  .esmaAccount {
    margin-bottom: 20px;
  }

  .confirm {
    margin-bottom: 20px;

    > div {
      position: absolute;
    }

    .confirmLabel {
      vertical-align: top;
      line-height: 20px;
    }

    .confirmControls:before {
      top: 5px;
    }

    .confirmControls:after {
      top: 7px;
    }
  }
}

.modalText {
  display: inline-block;
  margin-bottom: 20px;
  line-height: 20px;
}

.spinner {
  margin: auto;
}
